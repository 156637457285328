import axios from "../lib/axios";
import React, { useContext, useEffect, useState } from "react";
import { createContext } from "react";

export const LoginAuthContext = createContext();

export function LoginProvider({ children }) {
  //const [auth, setAuth] = useState();
  // const navi = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(getInitialUser);
  // 0 : Admin 1: Company 2: Individual 3: Driver
  const [count, setCount] = useState(initCount);
  async function handleLogin(user) {
    setIsLoading(true);
    try {
      const response = await axios

        .post("/web/login/user", user)
        .then((res) => {
          setIsLoading(false);

          setUser({ ...res.data, isRefresh: true });
          if(user.autoChk) {
            setCookie("cd", user.companyCode, 365);
            setCookie("id", user.userId, 365);
            setCookie("id_autoChk","Y", 365);
          } else {
            deleteCookie("cd");
            deleteCookie("id");
            deleteCookie("id_autoChk");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          alert("로그인에 실패하였습니다.\n다시 시도해주세요");
          console.log("response err : ", err);
        });
    } catch (err) {
      setIsLoading(false);
      alert("로그인에 실패하였습니다.\n다시 시도해주세요");
      console.log("login try err : ", err);
    }
  }
  async function handleDemoLogin(user) {
    setIsLoading(true);
    try {
      const response = await axios
        .post("/web/login/user", {
          companyCode: "DEMOCODE",
          userId: "DEMOUSER",
          password: "!dkdldhxl@#23",
        })

        .then((res) => {
          setIsLoading(false);

          setUser({ ...res.data, isRefresh: true });
        })
        .catch((err) => {
          setIsLoading(false);
          alert("로그인에 실패하였습니다.\n다시 시도해주세요");
          console.log("response err : ", err);
        });
    } catch (err) {
      setIsLoading(false);
      alert("로그인에 실패하였습니다.\n다시 시도해주세요");
      console.log("login try err : ", err);
    }
  }
  async function handleChangeUser(user) {
    setIsLoading(true);
    try {
      const response = await axios
        .post("/web/admin/session/login", user)
        .then((res) => {
          setIsLoading(false);

          setUser({ ...res.data, isRefresh: true });
          setTimeout(() => {
            if(window.location.pathname !== '/Map') {
              window.location.replace('https://iotlbs.com/Map')
            } else {
              window.location.reload();
            }
          }, 500);
        })
        .catch((err) => {
          setIsLoading(false);
          alert("로그인 조직 변경에 실패하였습니다.");
          console.log("response err : ", err);
        });
    } catch (err) {
      setIsLoading(false);
      alert("로그인 조직 변경에 실패하였습니다.");
      console.log("try error : ", err);
    }
  }

  function handleLogout() {
    console.log("logout web service");
    sessionStorage.setItem("user", null);
    setUser(null);
  }
  function stopRefresh() {
    setUser((prev) => ({ ...prev, isRefresh: false }));
  }
  function startRefresh() {
    setUser((prev) => ({ ...prev, isRefresh: true }));
  }
  function refreshCount(data) {
    setCount(data);
  }
  function setCookie(name, value, duedate) {
    let targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + duedate);
    document.cookie = name + "=" + encodeURIComponent(value) + "; path=/;expires=" + targetDate.toGMTString() + ";";
  }
  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <LoginAuthContext.Provider
      value={{
        handleChangeUser,
        handleLogout,
        handleLogin,
        handleDemoLogin,
        stopRefresh,
        startRefresh,
        refreshCount,
        user,
        isLoading,
        count,
      }}
    >
      {children}
    </LoginAuthContext.Provider>
  );
}

export const useAuth = () => useContext(LoginAuthContext);

function getInitialUser() {
  const user = sessionStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}

function initCount() {
  const count = { all: 0, drive: 0, pause: 0, end: 0 };
  return count;
}
