import Title from "../atom/Title";
import Input from "../atom/Input";
import PropTypes from "prop-types";
export default function FormRowInput({
  title,
  id,
  value,
  inputName,
  changeFun,
  placeholder,
  disabled
}) {
  return (
    <div className="form-row">
      <Title title={title} />
      <Input
        value={value}
        changeFun={changeFun}
        placeholder={placeholder}
        id={id}
        name={inputName}
        disabled={disabled}
      />
    </div>
  );
}
FormRowInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  inputName: PropTypes.string,
  disabled : PropTypes.bool
};
