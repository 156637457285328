import React, { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useAuth } from "../../../context/LoginContext";

export default function RealBtn() {
  const { language } = useContext(LanguageContext);
  const { user, startRefresh, stopRefresh } = useAuth();

  //실시간 버튼
  const realBtn = () => {
    user.isRefresh ? stopRefresh() : startRefresh();
  };

  return (
    <button
      type="button"
      className={`control-btn realtime ${user.isRefresh ? "on" : ""}`}
      onClick={realBtn}
    >
      <span className="label">
        {language === "KOR" ? "실시간" : "Realtime"}
      </span>
      <span className="content orange">{user.isRefresh ? "ON" : "OFF"}</span>
    </button>
  );
}
