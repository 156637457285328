import Title from "../atom/Title";
import PropTypes from "prop-types";

export default function ColorPicker({
  state,
  handlerClick,
  isOpen,
  onChange,
  title,
  disabled
}) {
  function handleColorChange(color) {
    onChange("color", color);
    handlerClick(false);
  }
  return (
    <>
      <div className="color-picker-box">
        <Title title={title} />
        <span
          className={`${state.color} color-picker-item`}
          onClick={disabled ? () => {} : (e) => handlerClick(true)}
          // style={{ backgroundColor: state.color }}
        ></span>
        {isOpen && (
          <div className="color-picker-box">
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#FF8787" }}
              onClick={() => handleColorChange("Red")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#5C7CFA" }}
              onClick={() => handleColorChange("Blue")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#20C997" }}
              onClick={() => handleColorChange("Green")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#FF922B" }}
              onClick={() => handleColorChange("Yellow")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#BE4BDB" }}
              onClick={() => handleColorChange("Pink")}
            ></button>
          </div>
        )}
      </div>
    </>
  );
}

ColorPicker.propTypes = {
  state: PropTypes.object.isRequired,
  handlerClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled : PropTypes.bool
};
