import React from "react";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/bundle";
import ItemSlide from "./atoms/ItemSlide";
interface SwiperProps {
  language: string;
}
interface titleObj {
  line_1: string;
  line_2: string;
}
interface SwiperData {
  title: titleObj;
  titleKR: titleObj;
  subENG: string;
  descENG: string;
  subKR: string;
  descKR: string;
  img: string;
}

const swiperData: SwiperData[] = [
  {
    titleKR: {
      line_1: "효율적인 관리를 위한",
      line_2: "통합위치관제서비스",
    },
    subKR: `국내 유일, GPS 트래커 제조부터 위치관제까지`,
    descKR: `독보적 자체 기술력을 보유한 (주)아이오티플렉스`,

    title: {
      line_1: "IOTLBS service ",
      line_2: "For efficient management",
    },
    subENG: "IoTPLEX GPS tracker manufacturing",
    descENG: `with its unique proprietary technology`,

    img: "../../assets/img/login/bg_car_2.jpg",
  },

  {
    titleKR: { line_1: "자동 운행일지 생성", line_2: "국세청 양식 다운로드" },
    subKR: "운행일지 작성하느라 시간이 오래 걸리셨나요?",
    descKR: `차량운행만 하시면 자동으로 생성되는 운행일지`,

    title: {
      line_1: "Create an automated log",
      line_2: "Download NTS Form",
    },
    subENG: "Time consuming log?",
    descENG: "Log it & drive!",
    img: "../../assets/img/login/bg_ocean.jpg",
  },
  {
    titleKR: {
      line_1: "외근직원 차량 배치는 물론",
      line_2: "운행현황 데이터로 관리비 절감",
    },
    subKR: "업무 외 사적 사용을 방지하여 유류비 절감 및",
    descKR: `필요한 업무에 차량 사용이 가능하도록 도와드립니다`,

    title: {
      line_1: "Got it! Vehicles for remote workers too.",
      line_2: "Data drives down costs!",
    },
    subENG: "Reduce fuel costs by preventing private use outside of work",
    descENG: "We help you use the vehicle for the tasks you need",
    img: "../../assets/img/login/bg_paddle.jpg",
  },
  {
    titleKR: {
      line_1: "주요 정보 모니터링 및 목표 달성을 위한",
      line_2: "운행 데이터를 한번에 확인할 수 있는 대시보드",
    },
    subKR: "차량 관리에 필요한 지표를 시각적으로 확인하고",
    descKR: `회사의 자산을 간편하게 관리할 수 있습니다`,

    title: {
      line_1: "Dash to success!",
      line_2: "Drive data in one glance!",
    },
    subENG: "Gone are the days of time-consuming driving logs",
    descENG:
      "Ditch the paperwork and let the system automatically track your every mile",
    img: "../../assets/img/login/bg_motor.jpg",
  },
];

export default function SwiperSlider({ language }: SwiperProps) {
  return (
    <>
      <div className="swiper">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          navigation={false}
          autoplay={{ delay: 5000 }}
        >
          {swiperData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ItemSlide
                  language={language}
                  titleKR={item.titleKR}
                  title={item.title}
                  subENG={item.subENG}
                  descENG={item.descENG}
                  subKR={item.subKR}
                  descKR={item.descKR}
                  img={item.img}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
