import React from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

export default function CriterionSelector({ onChange }) {
    const {language} = useContext(LanguageContext);
    return (
        <div className="custom-select">
                <select id='keywordType' onChange={onChange}>
                <option value='unit_nm'>{(language === 'KOR' ? '단말기명' : 'Dvc. Name' )}</option>
                <option value='unitid'>{(language === 'KOR' ? '단말번호' : 'Dvc. Num' )}</option>
            </select>
        </div>
    );
}

