import React from "react";

export default function DetailMinBtn({ state, setState }) {
  const handleMinimize = () => {
    setState((prev) => {
      return { ...prev, isMin: !prev.isMin };
    });
  };

  return (
    <button
      type="button"
      className={`c-size ${state.isMin ? "min" : "max"}`}
      onClick={handleMinimize}
    ></button>
  );
}
