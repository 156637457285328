import React, { useEffect, useRef } from "react";
import axios from "../../../lib/axios.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/LoginContext";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Select from "../../Atomic/molecules/Select";
import { useState } from "react";
import InputFilter from "../../Atomic/organism/InputFilter.jsx";
import TelInput from "../../Atomic/atoms/Tel_Input";
import Email from "../../Atomic/atoms/Email.jsx";
import { dataList } from "./ManagerMain.jsx";
export default function AgentAdd() {
  const navi = useNavigate();
  const location = useLocation();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();

  // State definition
  //==================================================================
  const [idx, setIdx] = React.useState("");
  const [rawData, setRawData] = React.useState({
    AG_IDX: "",
    u_type: "",
    org_idx: "",
    cmp_idx: "",
    brc_idx: "",
    agt_idx: "",
    act_yn: "Y",
    btoc_yn: "N",
    all_yn: "N",
    id: "",
    nm: "",
    user_id: "",
    pwd: "",
    pwd2: "",
    hp: "",
    tel: "",
    email: "",
    refresh_time: "60",
    grade: "AD",
    depart: "",
    make_utype: `${user.type}`,
    make_uidx: `${user.idx}`,
    head_office_yn: `${user.type === "C" ? "Y" : "N"}`,
    unitid: "",
  });
  const [CPN, setCPN] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [CTYPEData, setCTYPEData] = useState();
  const [DI, setDI] = useState();
  const [isHead, setIsHead] = useState(false);
  const [deviceData, setDeviceData] = useState();
  const [idReadOnly, setIdReadOnly] = useState(false);
  const [buso, setBuso] = useState([]);
  const [busoDirect, setBusoDirect] = useState(false);
  const [idChecked, setIdChecked] = useState(false);
  const [webAuth, setWebAuth] = useState([]);
  // Function definition
  //==================================================================
  function inputHandler(e) {
    const { name, value } = e.target;
    setRawData((prev) => ({ ...prev, [name]: value }));
  }
  const hpHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "hp1":
        temp = rawData.hp.split("-");
        temp[0] = value;
        if (temp[0] === "000") {
          alert("0000은 입력할 수 없습니다.");
          temp[0] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      case "hp2":
        temp = rawData.hp.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      case "hp3":
        temp = rawData.hp.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      default:
        break;
    }
  };
  const TelHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "tel1":
        temp = rawData.tel.split("-");
        temp[0] = value;
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      case "tel2":
        temp = rawData.tel.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      case "tel3":
        temp = rawData.tel.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      default:
        break;
    }
  };
  function submitFun() {
    Swal.fire({
      title: "등록하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        createNew();
      }
    });
  }
  function createNew() {
    if (rawData.org_idx === "") {
      Swal.fire({
        title: "지점/대리점명를 입력해주세요.",
      });
      return;
    }
    if (rawData.id === "") {
      Swal.fire({
        title: "ID를 입력해주세요.",
      });
      return;
    }
    if (idChecked === false) {
      Swal.fire({
        title: "ID 중복확인을 해주세요.",
      });
      return;
    }
    if (rawData.nm === "") {
      Swal.fire({
        title: "사용자명을 입력해주세요.",
      });
      return;
    }
    if (rawData.pwd !== "" && rawData.pwd) {
      if (rawData.pwd.length < 4) {
        Swal.fire({
          title: "비밀번호는 4자리 이상 입력해주세요.",
        });
        return;
      }
      if (!rawData.pwd2) {
        Swal.fire({
          title: "비밀번호를 입력해주세요.",
        });
        return;
      }
      if (rawData.pwd !== rawData.pwd2) {
        Swal.fire({
          title: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }
    } else {
      Swal.fire({
        title: "비밀번호를 입력해주세요.",
      })
      return;
    }
    if (rawData.grade === "") {
      Swal.fire({
        title: "등급을 입력해주세요.",
      });
      return;
    }
    if (rawData.grade === "FDV" && rawData.unitid === "") {
      Swal.fire({
        title: "단말번호를 선택해주세요.",
      })
      return;
    }
    if (rawData.hp.length < 13) {
      Swal.fire({
        title: "휴대폰 번호 입력해주세요.",
      });
      return;
    }
    if (rawData.act_yn === "") {
      Swal.fire({
        title: "사용여부를 선택해주세요.",
      });
      return;
    }
    if (rawData.all_yn === "") {
      Swal.fire({
        title: "접속허가를 선택해주세요.",
      });
      return;
    }    
    if (rawData.AG_IDX === "") {
      Swal.fire({
        title: "대메뉴 권한 그룹을 선택해주세요.",
      });
      return;
    }
    // if (rawData.menu_auth === "") {
    //   Swal.fire({
    //     title: "대메뉴 권한 그룹 입력해주세요.",
    //   });
    //   return;
    // }

    axios.post("/web/org/users/add", rawData).then((res) => {
      // axios.post("web/org/users/add", rawData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "등록되었습니다.",
        }).then((result) => {
          navi("/Mng/User");
        });
      } else {
        Swal.fire({
          title: "등록에 실패하였습니다.",
        });
      }
    });
  }

  //==============================================================
  // Compnay name filter

  function onClickFilter(e) {
    setRawData((prev) => ({
      ...prev,
      agt_idx: e.agt_idx,
      brc_idx: e.brc_idx,
      cmp_idx: e.cmp_idx,
      u_type: e.org_type,
      cmp_nm: e.org_nm,
      org_idx: e.idx,
    }));
    setCPN(
      `${
        e.org_type === "C"
          ? "회사"
          : e.org_type === "B"
          ? "지점"
          : e.org_type === "A"
          ? "대리점"
          : ""
      } > ${e.org_nm}`
    );
  }
  function onClickFilterDevice(e) {
    setRawData((prev) => ({
      ...prev,
      unitid: e.unitid,
    }));
    setDI(` ${e.unitid}`);
  }
  const nameShow = (e) => {
    if (e.org_type === "C") {
      return "회사 > " + e.org_nm;
    } else if (e.org_type === "B") {
      return "지점 > " + e.org_nm;
    } else if (e.org_type === "A") {
      return "대리점 > " + e.org_nm;
    } else {
      return "";
    }
  };
  const DeviceShow = (e) => {
    return `${e.unitid}`;
  };
  const placeholder = () => {
    if (language === "KOR") {
      return "지점/대리점명을 입력해주세요";
    } else if (language === "ENG") {
      return "enter /branch/agent name";
    } else {
      return "Enter name"; // Default placeholder for other languages
    }
  };
  const placeholderGrade = () => {
    if (language === "KOR") {
      return "단말번호를 입력해주세요.";
    } else if (language === "ENG") {
      return "enter usim number";
    } else {
      return "Enter name"; // Default placeholder for other languages
    }
  }

  function checkID() {
    if (rawData.id === "") {
      Swal.fire({
        title: "ID를 입력해주세요.",
      });
      return;
    }
    axios
      .post("/web/org/users/checkID", {
        id: rawData.id,
      })
      .then((res) => {
        if (res.data === "OK") {
          setIdReadOnly(true);
          setIdChecked(true);
          Swal.fire({
            title: "사용 가능한 ID입니다.",
          });
        } else {
          Swal.fire({
            title: "사용 불가능한 ID입니다.",
          });
          setIdReadOnly(false);
          setIdChecked(false);
        }
      });
  }
  function clearID() {
    setRawData((prev) => ({ ...prev, id: "" }));
    setIdReadOnly(false);
  }
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    if (location.state) {
      setIdx(location.state.idx);
    } else {
      setIdx("");
    }
  }, [location.state]);

  useEffect(() => {
    if (idx !== "") {
      // axios.get(`/web/org/users/detail?idx=${idx}`).then((res) => {
      axios.get(`/web/org/users/detail?idx=${idx}`).then((res) => {
        setRawData(res.data);
      });
    }
  }, [idx]);

  useEffect(() => {
    function getCompanyData() {
      axios
        .get(`/web/org/name/list?type=${user.type}&org_idx=${user.org_idx}`)
        // .get(`/web/org/name/list?type=${user.type}&org_idx=${user.org_idx}`)
        .then((res) => {
          setCompanyData(res.data);
        });
    }
    if (!companyData) {
      getCompanyData();
    }
  }, [companyData, user.org_idx, user.type]);

  useEffect(() => {
    function getDeviceData() {
        axios
          .get(
            `/web/org/users/fdv/list?type=${rawData.u_type}&org_idx=${rawData.org_idx}`
          )
          // .get(`/web/org/name/list?type=${user.type}&org_idx=${user.org_idx}`)
          .then((res) => {
            if(res.status === 200) {
              setDeviceData(res.data)
            } else {
              setDeviceData([]);
            };
          });
      }

    if (rawData?.u_type && rawData?.org_idx) { 
      getDeviceData();
    } else {
      setDeviceData([]);
    }
  }, [rawData.org_idx, rawData.u_type]);

  useEffect(() => {
    if (user.type === "C" && companyData) {
      const filteredCompanyData = companyData.filter((item) => {
        return item.org_type !== "C";
      });
      setCTYPEData(filteredCompanyData);
    }

    if (companyData) {
      setIsLoaded(true);
    }
  }, [companyData, user.type]);

  useEffect(() => {
    if (isHead && companyData) {
      const foo = companyData.filter((item) => {
        return item.org_type === "C";
      });
      setCPN(` ${nameShow(foo[0])}`);
      setRawData((prev) => ({
        ...prev,
        brc_idx: foo[0].brc_idx,
        agt_idx: foo[0].agt_idx,
        cmp_idx: foo[0].cmp_idx,
        u_type: foo[0].org_type,
        cmp_nm: foo[0].org_nm,
        org_idx: foo[0].idx,
      }));
    } else {
      setCPN("");
    }
  }, [isHead, companyData]);

  useEffect(() => {
    if (rawData.grade !== "FDV") {
      setRawData((prev) => ({ ...prev, unitid: "" }));
    }
  }, [rawData.grade]);

  useEffect(() => {
    if (user) {
      axios.get(`/web/org/users/depart/list`).then((res) => {
        setBuso(res.data);
      });
    }
  }, [user]);

  useEffect(() => {
    if (rawData.depart === "직접입력") {
      setBusoDirect(true);
    }
  }, [rawData.depart]);

  useEffect(() => {
    if(rawData.grade === 'AD') {
      setWebAuth([
        { name: "======", value: "" },
        {
          name:
            language === "KOR" ? "대리점 권한" : "Agent",
          value: 18,
        },
        {
          name:
            language === "KOR"
              ? "지점 권한"
              : "Branch, Agent",
          value: 12,
        },
        {
          name:
            language === "KOR"
              ? "회사권한 또는 관리자"
              : "Company, Admin",
          value: 11,
        },
      ])
    } else if(rawData.grade === 'DV' || rawData.grade === 'FDV') {
      setWebAuth([
        { name: "======", value: "" },
        {
          name: language === "KOR" ? "기사권한" : "Driver",
          value: 13,
        }
      ])
    }
  }, [rawData.grade])
  // Data definition
  //==================================================================

  const buttonData = [
    {
      labelKR: "취소",
      labelEN: "Cancel",
      classN: "purple",
      clickFun: () => {
        navi("/Mng/Agent");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Registration",
      classN: "purple",
      icon: true,
      clickFun: () => {
        submitFun();
      },
      position: "right",
    },
  ];
  //==================================================================

  const dis = {
    position: "relative",
  };

  //==================================================================
  function resetOrg() {
    setIsHead(false);
    setDI("");
    setRawData(prev => ({
      ...prev,
      org_idx:null,
      cmp_idx: null,
      brc_idx: null,
      agt_idx: null,
      cmp_nm: "",
      u_type: "",
      unitid: "",
      grade: prev.grade === "FDV" ? "DV" : prev.grade,
    }))
  }
  function resetDevice() {
    setDI("");
    setRawData((prev) => ({
      ...prev,
      unitid : "",
    }))
  }
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Mng/User"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "사용자 설정" : "User set"}
                </div>
              </div>
              <div className="sub-page-body">
                <table className="common-view-table">
                  <colgroup>
                    <col style={{ width: "19rem" }} />
                    <col />
                    <col style={{ width: "19rem" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      {user.type === "C" ? (
                        <>
                          <th>{language === "KOR" ? "회사명" : "Com name"}</th>
                          <td>
                            <label>
                              <input
                                type="checkbox"
                                name="head_office_yn"
                                id="head_office_yn"
                                checked={isHead === true ? "Y" : false}
                                onChange={() => {
                                  setIsHead(!isHead);
                                  setRawData((prev) => ({
                                    ...prev,
                                    head_office_yn: isHead === true ? "N" : "Y",
                                  }));
                                }}
                              />{" "}
                              본사소속 (본사 소속일 경우 체크하세요!)
                            </label>
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                      <th className="required">{language === "KOR" ? "사용자 ID" : "ID"}</th>
                      <td>
                        <div className="flex" style={dis}>
                          <div className="custom-input">
                            <input
                              name="id"
                              type="text"
                              onChange={inputHandler}
                              value={rawData.id}
                              readOnly={idReadOnly === true ? true : false}
                            />
                          </div>
                          <button
                            type="button"
                            className="common-btn purple sm"
                            onClick={(e) => checkID(e)}
                          >
                            <span>
                              {language === "KOR" ? "중복확인" : "Check ID"}
                            </span>
                          </button>
                          <button
                            type="button"
                            className="common-btn white  sm"
                            onClick={() => clearID()}
                          >
                            <span>
                              {language === "KOR" ? "초기화" : "Reset"}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required">
                        {language === "KOR" ? "지점/대리점명" : "Branch/Agent"}
                      </th>
                      <td colSpan="3">
                        <InputFilter
                          isHead={isHead}
                          language={language}
                          user={user}
                          companyData={
                            user.type === "C" ? CTYPEData : companyData
                          }
                          setState={setCPN}
                          setData={setRawData}
                          value={CPN}
                          onClickFilter={onClickFilter}
                          nameShow={nameShow}
                          placeholder={placeholder}
                          resetEvent={resetOrg}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th name="nm" className="required">
                        {language === "KOR" ? "사용자명" : "User name"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            id="nm"
                            name="nm"
                            type="text"
                            value={rawData.nm || ""}
                            onChange={inputHandler}
                          />
                        </div>
                      </td>
                      <th className="required">{language === "KOR" ? "비밀번호" : "Password"}</th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="password"
                            name="pwd"
                            onChange={inputHandler}
                            placeholder={
                              language === "KOR"
                                ? "비밀번호를 입력해주세요"
                                : "enter password"
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {language === "KOR"
                          ? "map 화면갱신주기"
                          : "Map refresh time"}
                      </th>
                      <td>
                        <Select
                          id="refresh_time"
                          short={"N"}
                          name="refresh_time"
                          options={[
                            {
                              name: language === "KOR" ? "미설정" : "No",
                              value: "9999",
                            },
                            {
                              name: `10 ${language === "KOR" ? "초" : "sec"}`,
                              value: "10",
                            },
                            {
                              name: `20 ${language === "KOR" ? "초" : "sec"}`,
                              value: "20",
                            },
                            {
                              name: `30 ${language === "KOR" ? "초" : "sec"}`,
                              value: "30",
                            },
                            {
                              name: `1 ${language === "KOR" ? "분" : "min"}`,
                              value: "60",
                            },
                            {
                              name: `2 ${language === "KOR" ? "분" : "min"}`,
                              value: "120",
                            },
                            {
                              name: `3 ${language === "KOR" ? "분" : "min"}`,
                              value: "180",
                            },
                          ]}
                          value={rawData.refresh_time}
                          changeFun={inputHandler}
                          def={rawData.refresh_time}
                        />
                      </td>
                      <th className="required">
                        {language === "KOR"
                          ? "비밀번호 확인"
                          : "Password again"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="password"
                            name="pwd2"
                            onChange={inputHandler}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th name="grade" className="required">
                        {language === "KOR" ? "등급" : "Grade"}
                      </th>
                      <td>
                        <div className="flex" style={dis}>
                          <Select
                            id="grade"
                            options={[
                              {
                                name: language === "KOR" ? "관리자" : "Admin",
                                value: "AD",
                              },
                              {
                                name: language === "KOR" ? "기사" : "Driver",
                                value: "DV",
                              },
                              {
                                name:
                                  language === "KOR"
                                    ? "고정기사"
                                    : "Fixed Driver",
                                value: "FDV",
                              },
                            ]}
                            value={rawData.grade}
                            def={rawData.grade}
                            name="grade"
                            changeFun={inputHandler}
                          />

                          {rawData.grade === "FDV" ? (
                            <InputFilter
                              language={language}
                              user={user}
                              companyData={deviceData}
                              setState={setDI}
                              setData={setRawData}
                              value={DI}
                              onClickFilter={onClickFilterDevice}
                              nameShow={DeviceShow}
                              placeholder={placeholderGrade}
                              rawData={rawData}
                              customStyle={{width:'20rem'}}
                              resetEvent={resetDevice}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <th name="hp" className="required">
                        {language === "KOR" ? "휴대폰 번호" : "Mobile"}
                      </th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="hp1"
                            name="hp1"
                            value={rawData.hp.split("-")[0]}
                            changeFun={hpHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={3}
                          />
                          -
                          <TelInput
                            id="hp2"
                            name="hp2"
                            value={rawData.hp.split("-")[1]}
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                          -
                          <TelInput
                            id="hp3"
                            name="hp3"
                            value={rawData.hp.split("-")[2]}
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {language === "KOR" ? " 관리자번호" : "Direct tel"}
                      </th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="tel1"
                            name="tel1"
                            value={rawData.tel.split("-")[0]}
                            changeFun={TelHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={2}
                          />
                          -
                          <TelInput
                            id="tel2"
                            name="tel2"
                            value={rawData.tel.split("-")[1]}
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={3}
                          />
                          -
                          <TelInput
                            id="tel3"
                            name="tel3"
                            value={rawData.tel.split("-")[2]}
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                      <th>E-mail</th>
                      <td>
                        <Email
                          id="email"
                          name="email"
                          value={rawData.email}
                          setRawData={setRawData}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th name="act_yn">
                        {language === "KOR" ? "사용여부" : "Active Y/N"}
                      </th>
                      <td>
                        <div className="custom-radio-group">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="act_yn"
                              value="Y"
                              defaultChecked={true}
                              onClick={inputHandler}
                            />
                            <div className="label">
                              {language === "KOR" ? "사용" : "Y"}
                            </div>
                          </label>                          
                        </div>
                      </td>
                      <th>{language === "KOR" ? "부서" : "Depart"}</th>
                      <td>
                        <div className="flex">
                          <Select
                            id="depart"
                            short="N"
                            options={[
                              {
                                name:
                                  language === "KOR"
                                    ? "==========="
                                    : "===========",
                                value: "",
                              },
                              ...buso.map((item) => ({
                                name: item.nm,
                                value: item.nm,
                              })),
                              {
                                name:
                                  language === "KOR" ? "직접입력" : "Direct",
                                value: "직접입력",
                              },
                            ]}
                            value={
                              rawData.depart === null ? "" : rawData.departs
                            }
                            def={rawData.depart === null ? "" : rawData.depart}
                            name="depart"
                            changeFun={inputHandler}
                          />
                          {busoDirect ? (
                            <>
                              <div
                                className="custom-input"
                                style={{ display: "flex", gap: 10 }}
                              >
                                <input
                                  name="depart"
                                  type="text"
                                  onChange={inputHandler}
                                  value={rawData.depart || ""}
                                />
                              </div>
                              <button
                                name="org_nm"
                                type="button"
                                className="common-btn white sm"
                                onClick={() => {
                                  setBusoDirect(false);

                                  setRawData((prev) => ({
                                    ...prev,
                                    depart: "",
                                  }));
                                }}
                              >
                                초기화
                              </button>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th name="all_yn" className="required">
                        {language === "KOR" ? "접속허가" : "Sign in allow"}
                      </th>
                      <td>
                        <div className="custom-radio-group">
                          <label className="custom-radio">
                            <input
                              key={rawData.all_yn !== "N"}
                              type="radio"
                              name="all_yn"
                              value="Y"
                              defaultChecked={rawData.all_yn !== "N"}
                              onChange={inputHandler}
                            />
                            <div className="label">
                              {language === "KOR" ? "허용" : "Y"}
                            </div>
                          </label>
                          <label className="custom-radio">
                            <input
                              key={rawData.all_yn === "N"}
                              type="radio"
                              name="all_yn"
                              value="N"
                              defaultChecked={rawData.all_yn === "N"}
                              onChange={inputHandler}
                            />
                            <div className="label">
                              {language === "KOR" ? "불가" : "N"}
                            </div>
                          </label>
                        </div>
                      </td>
                      <th name="menu_auth" className="required">
                        {language === "KOR" ? "대메뉴 권한 그룹" : "Menu Group"}
                      </th>
                      <td>
                        <Select
                          id="menu_auth"
                          options={webAuth}
                          changeFun={inputHandler}
                          name="AG_IDX"
                          def={rawData.AG_IDX}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
