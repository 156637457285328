import {
  NavLink,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useLang } from "../context/LanguageContext";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Root() {
  const { language } = useLang();

  function setScreenSize() {
    let vh = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
    window.addEventListener("resize", setScreenSize);

    return () => window.removeEventListener("resize", setScreenSize);
  });

  return (
    <>
      <Helmet>
        <html lang={language === "KOR" ? "ko" : "en"} />
      </Helmet>
      <>
        <Outlet />
      </>
    </>
  );
}
