import React from "react";
import { useState, useEffect } from "react";
import { useMap } from "../../context/MapContext";
import EventBtn from "./buttons/EventBtn";
import FullBtn from "./buttons/FullBtn";
import MapTypeBtn from "./buttons/MapTypeBtn";
import RealBtn from "./buttons/RealBtn";
import RefreshBtn from "./buttons/RefreshBtn";
import StreetBtn from "./buttons/StreetBtn";
import ZoomBtn from "./buttons/ZoomButton";
export default function MapComponents({ cur }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, streetMode, panoMap } = mapState;

  useEffect(() => {
    if (document.querySelector(`script[src="../assets/js/ui.js"]`)) {
      return;
    }
    const script = document.createElement("script");
    script.src = "../assets/js/ui.js";
    document.body.appendChild(script);
  }, []);

  return (
    <>
      {/*<!-- 지도컨트롤 -->*/}
      <div className="map-control">
        <div className="control-group">
          <EventBtn />
          <RealBtn />
          <RefreshBtn />
        </div>
        <div className="control-group map">
          <StreetBtn />
          <MapTypeBtn />
          <ZoomBtn />
          <FullBtn />
        </div>
      </div>

      {/*<!-- 거리뷰 커서 아이콘 -->*/}
      <div
        className={"street-cursor" + (streetMode && !panoMap ? " on" : "")}
        style={{ left: cur.x, top: cur.y }}
      >
        <div className="marker street">
          <div className="marker-icon">
            <span className="icon">
              <i className="ri-webcam-line"></i>
            </span>
          </div>
        </div>
      </div>
      {/*<!--e: 지도-->*/}
    </>
  );
}
