import React, { useState } from "react";
import { ISODateToDateTime, add_hyphen } from "../../../lib/common_func";
import Swal from "sweetalert2";
import axios from "axios";
import { useLang } from "../../../context/LanguageContext";

export default function ManualFixModal({
  data,
  searchOption,
  unit_nm,
  unitid,
  closeModal,
  search,
}) {
  const { language } = useLang();
  const [sendData, setSendData] = useState(data);

  const AddDataSave = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: '수정하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then(async (res) => {
        if(res.isConfirmed) {
            const check = ['dist','d_commute','d_general','gas_costs','etc_costs','tolls','parking_costs'];
            const keys = Object.keys(sendData);
            // number value check
            keys.map((key) => {
                if(check.includes(key) && Number.isNaN(parseFloat(sendData[key]))) {
                    Swal.fire({
                        title:'유효하지 않은 숫자값이 있습니다.'
                    })
                    return ;
                }
        
            })
            // dist cehck
            if(parseFloat(sendData.dist) < parseFloat(sendData.d_commute) + parseFloat(sendData.d_general)) {
                Swal.fire({
                    title:'주행거리는 출퇴근 거리와 업무용 거리의 합과 같거나 더 커야 합니다.'
                })
                return ;
            }
                
            const test = {...sendData,
                unitid: unitid,
                ed_time: ISODateToDateTime(sendData.ed_time),
                change_dist: parseFloat(sendData.dist) - parseFloat(data.dist)
            }
    
            await axios.post("/web/driving/detail/edit/man", test).then((res) => {
                Swal.fire({
                title : '수정되었습니다',
                confirmButtonText : '확인',
                }).then((res) => {
                closeModal();
                search(prev => !prev);
                })        
            }).catch((err) => {
            Swal.fire({
                title : '수정에 실패하였습니다',
                confirmButtonText : '확인',
            })
            console.log('err : ', err);
            })
        }
    })};
  const isNumberDecimalChk = (e) => {
    const valid = ".0123456789"; // 유효한 값
    let ok = "yes";
    let temp;
    let dotCount = 0;

    for (let i = 0; i < e.target.value.length; i++) {
      temp = "" + e.target.value.substring(i, i + 1);
      if (e.target.value.substring(i, i + 1) === ".") dotCount++;
      if (valid.indexOf(temp) == "-1") ok = "no";
    }

    if (ok == "no") {
      e.target.value = "";
      setSendData((prev) => ({...prev, [e.target.name]:0}));
      Swal.fire({
        title: "자연수 또는 소수만 입력 가능합니다",
      });
      e.target.focus();
    } else if (dotCount > 1) {
      e.target.value = "";
      setSendData((prev) => ({...prev, [e.target.name]:0}));
      Swal.fire({
        title: "소수점은 하나만 입력 가능합니다",
      });
      e.target.focus();
    }
  };

  const handleChange = (e) => {
    const check = ['dist','d_commute','d_general','gas_costs','etc_costs','tolls','parking_costs'];
    if(check.includes(e.target.name) && e.target.value === '') {
        setSendData((prev) => ({...prev, [e.target.name]:0}));
    } else {
        setSendData((prev) => ({...prev, [e.target.name]:e.target.value}));
    }
  };

  return (
    <>
      <div id="crew-search-panel" className="common-modal on">
                <div className="modal-inner manual">
                    <div className="modal-page">
                    <div className="page-header">
                        <div className="page-title">
                        <span className='blue'>{language === "KOR" ? "세부내역 수정" : "Detail Info"}</span>
                        </div>
                        <button onClick={closeModal} type="button" className="modal-close">
                            <i className="ri-close-fill icon"></i>
                        </button>
                    </div>
                    <div className="page-body">
                        <form onSubmit={AddDataSave}>
                            <table className='m_table' cellpadding="0" cellspacing="0" width="100%" id="modal_table">
                                <tbody>
                                    <tr>
                                        <td className='m_title'>단말정보</td>
                                        <td className='key'>단말기명</td>
                                        <td className='value blue'><div>{(unit_nm ?? '')}</div></td>
                                        <td className='key'>단말번호</td>
                                        <td className='value blue'><div>{(unitid && add_hyphen(unitid)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>주행정보</td>
                                        <td className='key'>주행 시작시간</td>
                                        <td className='value'><div>{(data && ISODateToDateTime(data.st_time)) ?? ''}</div></td>
                                        <td className='key'>주행 종료시간</td>
                                        <td className='value'><div>{(data && ISODateToDateTime(data.ed_time)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>주행 전 거리(km)</td>
                                        <td className='value'><div>{(data && parseFloat(data.st_mileage).toFixed(2)) ?? ''}</div></td>
                                        <td className='key'>주행 후 거리(km)</td>
                                        <td className='value'><div>{(data && parseFloat(data.ed_mileage).toFixed(2)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>운전자정보</td>
                                        <td className='key'>운전자명</td>
                                        <td className='value'><div>{(data?.u_nm)}</div></td>
                                        <td className='key'>부서</td>
                                        <td className='value'><div>{(data?.department)}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>거리정보</td>
                                        <td className='key'>주행거리(km)</td>
                                        <td className='value'>
                                            <input className='small' name="dist" defaultValue={(data && parseFloat(data?.dist).toFixed(2)) ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/>
                                            <input name="o_dist" defaultValue={data?.dist ?? 0} type="hidden" readonly />
                                        </td>
                                        <td className='key'></td>
                                        <td className='value'></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>출퇴근용(km)</td>
                                        <td className='value'><input className='small' name="d_commute" defaultValue={(data && parseFloat(data?.d_commute).toFixed(2)) ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>
                                        <td className='key'>일반업무용(km)</td>
                                        <td className='value'><input name="d_general" defaultValue={(data && parseFloat(data?.d_general).toFixed(2)) ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>비용정보</td>
                                        <td className='key'>주유비용(원)</td>
                                        <td className='value'><input className='small' name="gas_costs" defaultValue={data?.gas_costs ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>
                                        <td className='key'>통행비용(원)</td>
                                        <td className='value'><input name='tolls' defaultValue={data?.tolls ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>                                        
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>기타비용(원)</td>
                                        <td className='value'><input className='small' name='etc_costs' defaultValue={data?.etc_costs ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>
                                        <td className='key'>주차비용(원)</td>
                                        <td className='value'><input name='parking_costs' defaultValue={data?.parking_costs ?? 0} onKeyUp={isNumberDecimalChk} onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>기타</td>
                                        <td className='key'>메모</td>
                                        <td colspan="3"><input id="m_memo" name='memo' defaultValue={data?.memo ?? ''} maxLength="15" onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>
                                            <button type='submit' className='modify'>수정</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
    </>
  );
}