import React from 'react';
import { useState } from 'react';
export default function FoldBtn() {
    const [fold, setFold] = useState(false);
    const foldBtn = () => {
        const panel = document.querySelector(".track-list-panel");
        //const btn = document.querySelector("")
        if (fold) {
            panel.classList.remove("fold");
            setFold(false);
        }else {
            panel.classList.add("fold");
            setFold(true);
        }
    }
    return (
        <button type="button" className="fold-btn" onClick={foldBtn}><i className="ri-arrow-left-s-line icon"></i></button>
    );
}

