import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { useMap } from '../../../context/MapContext';

export default function StreetBtn() {
    const {language} = useContext(LanguageContext);
    const {mapState, mapDispatch} = useMap();
    const {map, streetMode, streetLayer} = mapState;
    const handleStreet = () => {        
        mapDispatch({type: 'SET_STREET_MODE', payload: !streetMode});
    }
    useEffect(()=>{                
        if(streetLayer) {
            streetMode ? streetLayer.setMap(map) : streetLayer.setMap(null);
        }
    },[map,streetMode, streetLayer])
    return (
        <button type="button" className={"control-btn street" + (streetMode ? ' on' : '')} onClick={handleStreet}>
                <i className="ri-webcam-line icon"></i>
                <div className="label">{(language === 'KOR' ? '거리뷰' : 'Street' )}</div>
        </button>
    );
}

