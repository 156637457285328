import React, { useState, useEffect } from "react";
import EventForm from "../organism/EventForm";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../../../../lib/axios";
import { useAuth } from "../../../../../context/LoginContext";
import { LanguageContext } from "../../../../../context/LanguageContext";
export default function AddEvent({ setIsAdd }) {
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  const [state, setState] = useState({
    allday: "N",
    color: "",
    driver: user.nm,
    fromDate: "",
    fromTime: "",
    memo: "",
    title: "",
    toDate: "",
    toTime: "",
    unitid: "",
    user,
  });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleBack = () => {
    setIsAdd(false);
  };

  function handlerClick() {
    setIsOpen(!isOpen);
  }

  const onChange = useCallback(
    (key, value) => {
      setState({ ...state, [key]: value });
    },
    [state]
  );

  useEffect(() => {
    // Create comapre date for start date and end date if start date > end date => alert
    const dateStart = new Date(state.fromDate);
    const dateEnd = new Date(state.toDate);

    const compareDate = dateStart.getTime() - dateEnd.getTime();

    if (compareDate > 0) {
      alert(
        language === "KOR"
          ? "시작일이 종료일보다 빠를 수 없습니다."
          : "Start date must be less than end date"
      );
      setState({ ...state, fromDate: "", toDate: "" });
    }

    // console.log(compareDate);
  }, [state.fromDate, state.toDate, state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === "") {
      Swal.fire({
        title:
          language === "KOR" ? "제목을 입력해주세요." : "Please enter a title.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }
    if (state.driver === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "운전자명을 입력해주세요."
            : "Please enter a driver.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.unitid === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "단말기명을 입력해주세요."
            : "Please enter a device.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.color === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "색상을 선택해주세요."
            : "Please select a color.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.fromDate === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "시작일을 입력해주세요."
            : "Please enter a start date.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.toDate === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "종료일을 입력해주세요."
            : "Please enter a end date.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }
    if (state.fromTime === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "시작시간을 입력해주세요."
            : "Please enter a start time.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.toTime === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "종료시간을 입력해주세요."
            : "Please enter a end time.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }

    console.log(state);
    Swal.fire({
      title:
        language === "KOR" ? "저장하시겠습니까?" : "Would you like to save?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Check if the user confirmed
        axios
          .post("/web/schedule/add", state)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              alert(language === "KOR" ? "저장되었습니다." : "Saved", language);
              
              window.location.reload();
            } else if (response.status === 500) {
              console.error("Server error: ", response.data);
              alert(
                language === "KOR"
                  ? "서버 오류가 발생했습니다."
                  : "Server error occurred",
                language
              );
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
            Swal.fire({
              title:
                language === "KOR"
                  ? "오류가 발생하였습니다."
                  : "An error occurred",
              confirmButtonText: language === "KOR" ? "확인" : "OK",
              showCancelButton: true,
            });
          });
      }
    });
  };

  return (
    <div className="common-modal on" style={{ zIndex: "1001" }}>
      <div className="modal-inner">
        <div className="modal-page">
          <div className="page-header">
            <div className="page-title">
              <span>{language === "KOR" ? "차량예약" : "Reservation"}</span>
            </div>
            <button
              type="button"
              className="modal-close"
              onClick={() => setIsAdd(false)}
            >
              <i className="ri-close-fill icon"></i>
            </button>
          </div>
          <div className="page-body">
            <EventForm
              isOpen={isOpen}
              handlerClick={handlerClick}
              state={state}
              updateState={onChange}
              cancelFun={handleBack}
              submitFun={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
