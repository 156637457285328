import { useState, useEffect } from "react";
import { useMap } from "../../../context/MapContext";

export default function GeoMarker({ item, geoCircle, setGeoCircle }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, cluster, selectGeoItem, pathData } = mapState;
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }

    // map이 언마운트될때 삭제
    return () => {
      if (marker) {
        geoCircle.g1?.setMap(null);
        marker.eventTarget.parentNode.classList.remove("active");
        marker.setMap(null);
      }
    };
  }, [marker, naver]);

  useEffect(() => {
    if (map && marker) {
      marker.setOptions({
        position: new naver.maps.LatLng(item.lat, item.lng),
        map: map,
        icon: {
          content: `
                    <div class="marker geo">
                        <div class="marker-icon">
                            <span class="icon">
                            <i class="ri-community-fill"></i>
                            </span>
                        </div>
                        <div class="marker-name">${item.name}</div>
                    </div>
                `,
          size: new naver.maps.Size(54, 54),
          anchor: new naver.maps.Point(27, 54),
        },
        markerId: item.idx,
      });
      marker.eventTarget.parentNode.classList.add("size");

      //마커의 클릭에는 selected state변경만 유도
      const listener = naver.maps.Event.addListener(marker, "click", (e) => {
        if (selectGeoItem && selectGeoItem.idx === item.idx) {
          mapDispatch({ type: "BLUR" });
        } else {
          mapDispatch({
            type: "SET_SELECT_GEO_ITEM",
            payload: item,
          });
        }
        naver.maps.Event.removeListener(listener);
      });
    }
  }, [map, marker, naver, item, cluster, selectGeoItem, mapDispatch]);

  useEffect(() => {
    //selected state변경이되면 마커위치로 이동하고 클래스 토글
    if (marker) {
      if (selectGeoItem && selectGeoItem.idx === item.idx) {
        map.setZoom(18, false);
        map.panTo(
          {
            ...marker.position,
            y: marker.position.y - 0.00012, //약간 위로 보이게 보정
          },
          {
            duration: 300,
            easing: "easeOutCubic",
          }
        );
        marker.eventTarget.parentNode.classList.add("active");
        geoCircle.g1?.setMap(null);
        setGeoCircle((prev) => ({
          g1: new naver.maps.Circle({
            map: map,
            center: marker.position,
            radius: item.radius,
            fillColor: "#2C2685",
            fillOpacity: 0.14,
            strokeWeight: 4,
            strokeColor: "#2C2685",
          }),
        }));
      } else {
        marker.eventTarget.parentNode.classList.remove("active");
        geoCircle.g1?.setMap(null);
      }
    }
  }, [map, item, marker, selectGeoItem, pathData]);

  return null;
}
