import React from "react";
import { useMap } from "../../../context/MapContext";

export default function DetailCloseBtn() {
  const { mapDispatch } = useMap();

  const handleClose = () => {
    mapDispatch({ type: "BLUR" });
  };

  return (
    <button type="button" className="close" onClick={handleClose}></button>
  );
}
