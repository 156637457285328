import PropTypres from "prop-types";

export default function Input({
  value,
  changeFun,
  classN,
  placeholder,
  id,
  name,
  disabled
}) {
  return (
    <div className="row-content">
      <div className="form-input">
        <input
          type="text"
          value={value}
          onChange={changeFun}
          placeholder={placeholder}
          id={id}
          name={name}
          className={`${classN}`}
          disabled={disabled ?? false}
        />
      </div>
    </div>
  );
}

Input.propTypes = {
  value: PropTypres.string.isRequired,
  changeFun: PropTypres.func,
  classN: PropTypres.string,
  placeholder: PropTypres.string,
  id: PropTypres.string,
  name: PropTypres.string,
  disabled : PropTypres.bool
};
