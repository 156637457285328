import "../../../assets/lib/tui/tui-grid.css";

const tableStyle = {
  row: {
    odd: {
      background: "#fff",
    },
    even: {
      background: "#f8f9fa",
    },
    hover: {
      background: "#d8fae6",
    },
  },
  cell: {
    normal: {
      border: "#eee",
      showVerticalBorder: true,
      showHorizontalBorder: true,
    },
  },
  outline: {
    border: "#eee",
    showVerticalBorder: false,
  },
};

export default tableStyle;
