import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function RefreshBtn() {
    const navi = useNavigate;
    const handleRefresh = () => {
        window.history.replaceState({}, document.title);
        document.location.reload();
    }
    return (
        <button type="button" className="control-btn refresh" onClick={handleRefresh}>
        <i className="ri-refresh-line icon"></i>
    </button>
    );
}

