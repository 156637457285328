import { createContext, useContext, useState } from "react";

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const storageLanguage = sessionStorage.getItem("language");
  const [language, setLanguage] = useState(
    storageLanguage ? storageLanguage : "KOR"
  );
  const [isFull, setIsFull] = useState(false);
  const changeLanguage = (e) => {
    setLanguage((prev) => {
      if (prev === e.target.innerText) {
        return prev;
      }
      if (prev === "KOR") {
        sessionStorage.setItem("language", "ENG");
        return "ENG";
      }
      sessionStorage.setItem("language", "KOR");
      return "KOR";
    });
  };
  const activeFull = () => {
    document.querySelector("html").classList.add("full-mode");
    setIsFull(true);
  };
  const inacativeFull = () => {
    document.querySelector("html").classList.remove("full-mode");
    setIsFull(false);
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, isFull, activeFull, inacativeFull }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLang = () => useContext(LanguageContext);
